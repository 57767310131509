import { cva, type VariantProps } from 'class-variance-authority'

import { cls } from '@/utils'

const seperatorVariants = cva('bg-text-subdued rounded-full', {
  variants: {
    direction: {
      horizontal: 'h-0.5',
      vertical: 'w-0.5',
    },
  },
  defaultVariants: {
    // variant: 'outline',
    direction: 'horizontal',
  },
})

export interface SeperatorProps extends VariantProps<typeof seperatorVariants> {
  className?: string
}

export default function Seperator({ className, direction }: SeperatorProps) {
  return (
    <div className='flex justify-center items-center'>
      <div className={cls(seperatorVariants({ direction }), className)} />
    </div>
  )
}
