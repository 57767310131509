import { useAtomValue } from 'jotai'
import Dialog from '@/components/dialog'
import { auth0SignInAtom } from '@/atoms'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import { cls } from '@/utils'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import Button from '@/components/button'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useAmplitude from '@/hooks/useAmplitude'
import Image from '../image'
import { useCachedNewFeatureAnnouncement } from '@/hooks/useNewFeatureAnnouncement'
import { useRouter } from 'next/navigation'
import Video from '../video'
import Markdown from '../markdown'
import usePopups from '@/hooks/usePopups'

export default function NewFeatureDialog() {
  const isLogin = useAtomValue(auth0SignInAtom)
  const { track } = useAmplitude()
  const { data: newFeatureAnnouncement, isValidating: newFeatureAnnouncementLoading } =
    useCachedNewFeatureAnnouncement()
  const router = useRouter()
  const content = newFeatureAnnouncement?.content ?? ''
  const { showNewFeatureDialog, closeNewFeatureDialog } = usePopups()

  const handleClose = useCallback(() => {
    closeNewFeatureDialog()
  }, [closeNewFeatureDialog])

  const handleOk = useCallback(() => {
    if (newFeatureAnnouncement?.ok_event) {
      track(newFeatureAnnouncement.ok_event)
    }
    handleClose()
    if (newFeatureAnnouncement?.next_url) {
      router.push(newFeatureAnnouncement.next_url)
    }
  }, [track, handleClose, newFeatureAnnouncement, router])

  const viewEventTrackedRef = useRef(false)

  const { isBelowMd } = useBreakpoint('md')

  const previewImageUrl = useMemo(() => {
    return isBelowMd ? newFeatureAnnouncement?.mobile_image : newFeatureAnnouncement?.pc_image
  }, [isBelowMd, newFeatureAnnouncement])

  const previewVideoUrl = useMemo(() => {
    return isBelowMd ? newFeatureAnnouncement?.mobile_video : newFeatureAnnouncement?.pc_video
  }, [isBelowMd, newFeatureAnnouncement])

  const realOpen = !!newFeatureAnnouncement && !newFeatureAnnouncement?.disabled && showNewFeatureDialog

  useEffect(() => {
    if (realOpen && !viewEventTrackedRef.current && newFeatureAnnouncement?.view_event) {
      track(newFeatureAnnouncement?.view_event)
      viewEventTrackedRef.current = true

      if (newFeatureAnnouncement?.next_url && newFeatureAnnouncement?.next_url?.startsWith('/')) {
        router.prefetch(newFeatureAnnouncement.next_url)
      }
    }
  }, [realOpen, track, newFeatureAnnouncement, router])

  const footerButton = (
    <Button variant='primary' className='w-full text-body-md font-normal tracking-15 align-middle' onClick={handleOk}>
      {newFeatureAnnouncement?.ok_text || 'Learn more'}
    </Button>
  )

  return (
    <Dialog
      open={realOpen}
      title={null}
      titleClassName='mb-3'
      className='gap-0 w-[343px] md:w-[578px] p-4 md:p-6'
      closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='relative h-max max-h-[90vh]'>
        <div className='flex flex-col gap-6'>
          {previewVideoUrl ? (
            <Video
              muted
              autoPlay
              loop
              controls
              hasAudioTrack
              preload='auto'
              className='cursor-pointer rounded-md border border-border aspect-video w-full'
              src={previewVideoUrl}
              poster={previewImageUrl}
              // onClick={handleOk}
            />
          ) : (
            <Image
              className='cursor-pointer rounded-md border border-border h-[130px] md:h-[224px] object-cover object-top'
              src={previewImageUrl}
              alt='new-feature-preview'
              onClick={handleOk}
            />
          )}
          <div className='flex items-start justify-between gap-4'>
            <div className='flex items-center gap-1 text-text font-bold text-heading-2xl leading-[normal] min-h-10'>
              {newFeatureAnnouncement?.title}
            </div>
            <Button
              variant='transparent'
              className={cls(
                'p-0 size-10 rounded-lg focus-visible:shadow-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted border-0 shadow-none',
              )}
              aria-label='dialog close button'
              onClick={handleClose}
            >
              <IconClose className='size-6 text-icon' />
              <span className='sr-only'>Close</span>
            </Button>
          </div>
        </div>
        <div className='w-full mt-3 text-text flex flex-col gap-6 md:gap-8 text-body-md tracking-15 h-full'>
          <Markdown>{content}</Markdown>
          <div className='' aria-label='placeholder'>
            {footerButton}
          </div>
        </div>
      </div>
    </Dialog>
  )
}
