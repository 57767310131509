import Button from '@/components/button'
import { PersonaFormProps } from './_common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cls } from '@/utils'
import IconOther from '@/public/assets/persona/loading.svg'
import IconGoogle from '@/public/assets/persona/google.svg'
import IconX from '@/public/assets/persona/x.svg'
import IconXDark from '@/public/assets/persona/x-dark.svg'
import IconFacebook from '@/public/assets/persona/facebook.svg'
import IconTikTok from '@/public/assets/persona/tiktok.svg'
import IconTikTokDark from '@/public/assets/persona/tiktok-dark.svg'
import IconYouTube from '@/public/assets/persona/youtube.svg'
import IconInfluencer from '@/public/assets/persona/influencer.svg'
import IconNews from '@/public/assets/persona/news.svg'
import IconVoice from '@/public/assets/persona/voice.svg'

import useAmplitude from '@/hooks/useAmplitude'
import useTheme from '@/hooks/useTheme'

export default function stringForm({ onSubmit, value, index, total }: PersonaFormProps) {
  const { theme } = useTheme()

  const options = useMemo(
    () => [
      {
        value: 'search_engine',
        icon: IconGoogle,
        label: 'Google / Other search engine',
      },
      {
        value: 'x',
        icon: theme === 'dark' ? IconXDark : IconX,
        label: 'X (Twitter)',
      },
      {
        value: 'fb/ins',
        icon: IconFacebook,
        label: 'Facebook / Instagram',
      },
      {
        value: 'tiktok',
        icon: theme === 'dark' ? IconTikTokDark : IconTikTok,
        label: 'TikTok',
      },
      {
        value: 'youtube',
        icon: IconYouTube,
        label: 'YouTube',
      },
      {
        value: 'influencer',
        icon: IconInfluencer,
        label: 'Influencer',
      },
      {
        value: 'press',
        icon: IconNews,
        label: 'In the press',
      },
      {
        value: 'word_of_mouth',
        icon: IconVoice,
        label: 'Word of mouth',
      },
      {
        value: 'other',
        icon: IconOther,
        label: 'Other',
        className: 'md:col-span-2',
      },
    ],
    [theme],
  )

  const [source, setSource] = useState<string | ''>(value?.source ?? '')
  const [sourceOther, setSourceOther] = useState<string | ''>(value?.source_other ?? '')

  const showInput = source === 'other'
  const showConfirmBtn = showInput

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:source')
  }, [track])

  const handleSubmit = useCallback(() => {
    if (source) {
      onSubmit({
        source,
        source_other: sourceOther,
      })
    }
  }, [source, onSubmit, sourceOther])

  const handleValueChange = useCallback(
    (v: string) => {
      setSource(v as string)

      if (v && v !== 'other') {
        onSubmit({ source: v as string })
      }
    },
    [onSubmit],
  )

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[640px] flex flex-col items-start justify-start'>
        <div className='w-full p-6 text-heading-lg font-bold flex flex-col gap-2 tracking-45' aria-label='title'>
          <span className='text-body-lg font-normal tracking-32 text-text-subdued'>
            Question {index + 1} / {total}
          </span>
          <span className='text-body-lg text-text-subdued tracking-32 font-normal'>
            Your creation is on the way! 3 simple questions to help us service you better~
          </span>
          <span>How did you find us?</span>
        </div>
        <div className='w-full px-6' aria-label='radio'>
          <RadioGroup
            value={String(source)}
            className='grid grid-cols-1 md:grid-cols-2 gap-4'
            onValueChange={handleValueChange}
          >
            {options.map(({ label, value, icon: Icon, className }) => {
              const id = `persona-source-${value}`
              const active = value === source
              return (
                <div
                  key={value}
                  className={cls(
                    'flex items-center size-full justify-between min-h-max rounded-lg border-2 border-b-4 border-solid border-border shadow-sm cursor-pointer hover:bg-surface-hover hover:border-border-hover',
                    className,
                    active
                      ? 'bg-surface-active hover:bg-surface-active bg-opacity-10 border-border-active hover:border-border-active'
                      : 'bg-surface',
                    value === 'other' && showInput ? '' : 'h-[66px]',
                  )}
                  onClick={active && source !== 'other' ? handleSubmit : undefined}
                >
                  <label
                    htmlFor={id}
                    className='relative size-full min-h-full flex flex-col items-center justify-center gap-4 p-3 cursor-pointer'
                  >
                    <div className='w-full flex gap-4 items-center h-9'>
                      <Icon className='w-[34px] h-auto max-h-[34px]' />
                      <span className='text-heading-md font-bold tracking-32'>{label}</span>
                    </div>
                    {showInput && value === 'other' ? (
                      <input
                        className='h-10 border rounded-md focus-visible:outline-none focus-visible:border-border-active cursor-auto min-w-full w-full md:w-[568px] px-4 py-2'
                        width={568}
                        autoComplete='off'
                        placeholder='Please specify'
                        value={sourceOther}
                        onChange={(e: any) => setSourceOther(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    ) : null}
                  </label>
                  <RadioGroupItem id={id} className='hidden invisible' value={value} />
                </div>
              )
            })}
          </RadioGroup>
        </div>
        <div className='w-full p-6 pt-4 flex justify-end h-20'>
          <Button
            variant='primary'
            className='rounded-md'
            disabled={!sourceOther || !showConfirmBtn}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
