import { cls, whisper } from '@/utils'

export interface SubmitEntrySpotlightProps {
  className?: string
}

export default function SubmitEntrySpotlight({ className }: SubmitEntrySpotlightProps) {
  return (
    <div className={cls('flex flex-col gap-8', className)}>
      <div className='' aria-label='info'></div>
      <div className='' aria-label='form'></div>
      <div className='' aria-label='button'></div>
    </div>
  )
}
