import { useCachedMarketingEvents } from '@/hooks/useMarketingEvents'
import { cls, whisper } from '@/utils'
import Scrollable from '../scrollable'
import EventCard from '../event-card'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Triangle from '../triangle'
import Button from '../button'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import useEventAction from '@/hooks/useEventAction'
import useAmplitude from '@/hooks/useAmplitude'

export interface SubmitEntryEventsProps {
  className?: string
}

export default function SubmitEntryEvents({ className }: SubmitEntryEventsProps) {
  const { data: marketingEvents } = useCachedMarketingEvents()
  const [activeEventId, setActiveEventId] = useState<string | null>(null)
  const activeEvent = useMemo(
    () => marketingEvents?.find((event) => event.id === activeEventId) ?? null,
    [marketingEvents, activeEventId],
  )

  // Set the first event as active by default
  useEffect(() => {
    if (marketingEvents && !activeEventId) {
      setActiveEventId(marketingEvents[0].id)
    }
  }, [marketingEvents, activeEventId])

  const { handleClick: handleEventClick } = useEventAction(activeEvent)
  const { track } = useAmplitude()

  const openEventDetails = useCallback(
    async (e: any) => {
      track('click:event:card', {
        event_id: activeEvent?.id,
        source: 'submit-entry',
      })
      handleEventClick?.(e)
    },
    [handleEventClick, track, activeEvent],
  )

  return (
    <div className={cls('flex flex-col gap-8', className)}>
      <div className='flex flex-col' aria-label='info'>
        <span className='text-heading-lg font-bold text-black dark:text-white mb-4'>Select Event</span>
        <Scrollable className='gap-4 px-1'>
          {marketingEvents?.map((event) => {
            const isActive = event.id === activeEventId
            return (
              <div key={event.id} className={cls('flex flex-col items-center gap-0.5 w-70')}>
                <div
                  key={event.id}
                  className={cls(
                    'rounded-lg flex flex-col items-center justify-center',
                    isActive ? 'border-primary border' : 'border-border border',
                  )}
                >
                  <EventCard
                    key={event.id}
                    hideButton
                    data={event}
                    source='submit-entry'
                    className={cls('rounded-[10px]')}
                    onClick={() => setActiveEventId(event.id)}
                  />
                </div>
                <Triangle className={cls('', isActive ? 'text-surface-subdued' : 'text-transparent')} />
              </div>
            )
          })}
        </Scrollable>
        <div className='w-full p-4 rounded-md bg-surface-subdued flex flex-col gap-2' aria-label='event info'>
          <div className='w-full flex justify-between items-center' aria-label='event info toolbar'>
            <span className='text-heading-md font-bold tracking-32'>{activeEvent?.title}</span>
            <Button variant='transparent' onClick={openEventDetails}>
              <div className='flex'>
                <span className='px-1 tracking-15'>Details</span>
                <IconChevronRight className='size-5 shrink-0 text-icon' />
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-4' aria-label='form'>
        <div className='text-heading-lg tracking-45 font-bold'>Submission</div>
        <div className='flex flex-col md:flex-row' aria-label='widgets'></div>
      </div>
      <div className='' aria-label='button'></div>
    </div>
  )
}
