import { useAtom } from 'jotai'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { cls, formatCurrency, formatMoney, numberWithCommas } from '@/utils'
import { getTobCreditDialogOpenAtom } from '@/atoms'
import { useMemo, useState } from 'react'
import { getTobTopupCheckoutSession } from '@/service/tob.service'
import { useCachedToBPricingTable } from '@/hooks/useTobPricingTable'
import Seperator from '../seperator'

export default function GetTobCreditDialog() {
  const [getCreditDialogOpen, setGetCreditDialogOpen] = useAtom(
    getTobCreditDialogOpenAtom,
  )
  const [currentId, setCurrentId] = useState('')
  const { data: pricingTable } = useCachedToBPricingTable()
  const productsList = useMemo(() => {
    return pricingTable?.top_up_products ?? []
  }, [pricingTable])
  const [paymentCompleteOpen, setPaymentCompleteOpen] = useState(false)

  const confirmRecharge = async () => {
    const currentProduct = productsList.find(
      (item) => item.prices[0].id === currentId,
    )
    if (currentProduct) {
      const win = window.open('', '_blank')
      const pathname = window?.location.pathname || ''
      const redirectUrl = encodeURIComponent(`${pathname}?step=integrate`)
      try {
        const priceId = currentProduct.prices[0].id
        const successUrl = `${window.location.origin}/payment/success?redirectUrl=${redirectUrl}`
        const cancelUrl = `${window.location.origin}/payment/cancel?redirectUrl=${redirectUrl}`

        const url = await getTobTopupCheckoutSession({
          price_lookup_key: priceId || '',
          success_url: successUrl,
          quantity: 1,
          cancel_url: cancelUrl,
        })

        win?.location.assign(url)
        win?.focus()
      } catch (error) {
        win?.close()
      }
      setGetCreditDialogOpen(false)
      setPaymentCompleteOpen(true)
    }
  }

  const confirmRefresh = () => {
    window.location.reload()
  }

  const renderCurrentPrice = () => {
    const currentProduct = productsList.find(
      (item) => item.prices[0].id === currentId,
    )
    if (currentProduct) {
      return `Purchase for ${formatCurrency(currentProduct.prices[0].currency || '', '$')}${formatMoney(Number(currentProduct.prices[0].unit_amount) / 100)}`
    }
    return 'Purchase'
  }

  return (
    <>
      <Dialog
        open={getCreditDialogOpen}
        title='Top up'
        titleClassName='mb-3'
        className='gap-0 w-[343px] md:w-[600px] p-4 md:p-6'
        footerClassName='mt-[30px]'
        footer={null}
        onOpenChange={(open) => setGetCreditDialogOpen(open)}
      >
        <div className='w-[310px] md:w-[352px] mb-5 text-text-subdued'>
          The fund you topped up will not expire at the end of the billing
          cycle.
        </div>
        <div className='py-4 md:py-6 gap-2 grid grid-cols-1 md:grid-cols-2 text-body-md tracking-15'>
          {productsList.map((item) => (
            <div
              key={item?.prices[0].id}
              className={cls(
                'text-center py-4 border rounded-md cursor-pointer hover:border-border-hover hover:bg-surface-hover',
                currentId === item?.prices[0].id
                  ? 'border-surface-primary border-2 py-[15px] hover:border-surface-primary'
                  : '',
              )}
              onClick={() => setCurrentId(item?.prices[0].id)}
            >
              <div className='flex flex-col items-center'>
                <div>
                  <span className='text-text-interactive font-medium px-1'>
                    {numberWithCommas(item?.unit_amount / 1000)}
                  </span>
                  seconds video generation
                </div>
                <div className='flex gap-1'>
                  <Seperator className='w-4' />
                  <span className='text-text-subdued font-bold tracking-15'>
                    or
                  </span>
                  <Seperator className='w-4' />
                </div>
                <div>
                  <span className='text-text-interactive font-medium px-1'>
                    {numberWithCommas(
                      item?.exchange_to_720p_image?.unit_amount ?? '--',
                    )}
                  </span>
                  images in 720p
                </div>
                <div className='text-body-lg font-medium text-text-subdued mt-2'>
                  {formatCurrency(item?.prices[0].currency ?? '', '$')}{' '}
                  {formatMoney(Number(item?.prices[0].unit_amount) / 100)}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          disabled={currentId === ''}
          variant='primary'
          className='w-full rounded-md'
          data-testid='purchase-button'
          onClick={confirmRecharge}
        >
          {renderCurrentPrice()}
        </Button>
      </Dialog>
      <Dialog
        open={paymentCompleteOpen}
        title='Payment completed?'
        titleClassName='mb-3'
        className='gap-0 w-[343px] md:w-100 p-4 md:p-6'
        footerClassName='mt-[30px]'
        footer={null}
        onOpenChange={(open) => setPaymentCompleteOpen(open)}
      >
        <div className='mb-5 text-text-subdued'>
          If you have completed the payment, please refresh the page to have the
          new balance of credits.
        </div>
        <div className='mb-8 text-text-subdued'>
          If you have an issue of the payment, please contact us via{' '}
          <a className='text-text-interactive' href='mailto:billing@haiper.ai'>
            billing@haiper.ai
          </a>
        </div>
        <Button
          disabled={currentId === ''}
          variant='primary'
          className='w-full mb-2 rounded-md'
          data-testid='refresh-button'
          onClick={confirmRefresh}
        >
          Refresh
        </Button>
      </Dialog>
    </>
  )
}
