import { PoNVoid } from '@/types'
import { ReactElement } from 'react'
import { Tabs as BaseTabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cls } from '@/utils'

export interface TabItemProps {
  value: string
  label: string | ReactElement
}

export interface TabsProps {
  variant?: 'outline' | 'solid'
  className?: string
  value: string
  items: TabItemProps[]
  onChange: (value: string) => PoNVoid
  listClassName?: string
  itemClassName?: string
  activeItemClassName?: string
  inActiveItemClassName?: string
}

export default function Tabs({
  value,
  items,
  variant,
  onChange,
  className,
  listClassName,
  itemClassName,
  activeItemClassName,
  inActiveItemClassName,
}: TabsProps) {
  return (
    <BaseTabs
      value={value}
      className={cls('rounded-md bg-surface-hover p-0.5 h-10', className)}
      activationMode='manual'
      onValueChange={(v) => onChange?.(v)}
    >
      <TabsList className={cls('w-full rounded-md p-0 h-9', listClassName)}>
        {items.map((item) => {
          const isActive = value === item.value
          return (
            <TabsTrigger
              key={item.value}
              value={item.value}
              variant={variant}
              className={cls(
                'rounded-md active:bg-surface-primary active:text-text-on-color text-body-lg text-icon py-2 px-5 h-full',
                isActive ? 'font-medium' : 'bg-transparent',
                itemClassName,
                isActive ? activeItemClassName : inActiveItemClassName,
              )}
            >
              {item.label}
            </TabsTrigger>
          )
        })}
      </TabsList>
    </BaseTabs>
  )
}
