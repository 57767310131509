import { cls } from '@/utils'
import IconPolygon from '@/public/assets/polygon.svg'

export interface TriangleProps {
  className?: string
}

export default function Triangle({ className }: TriangleProps) {
  return (
    <div className={cls('relative flex items-center justify-center w-7 h-[14px] overflow-hidden')}>
      <IconPolygon className={cls('absolute w-full inset-x-0 top-0', className)} />
    </div>
  )
}
