import { cls } from '@/utils'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Button from '../button'
import Video from '../video'
import Image from '../image'
import { useMemo } from 'react'

export interface MarkdownProps {
  className?: string
  children: string
  native?: boolean
}

const markdownStyle = cls(
  '[&_h1]:text-heading-2xl [&_h1]:font-bold [&_h1]:text-center [&_h1]:my-2',
  '[&_h2]:text-heading-xl [&_h2]:font-bold [&_h2]:text-center [&_h2]:my-2',
  '[&_h3]:text-heading-lg [&_h3]:font-bold [&_h3]:text-center [&_h3]:my-2',
  '[&_h4]:text-heading-md [&_h4]:font-medium [&_h4]:my-2',
  '[&_h5]:text-heading-sm [&_h5]:font-bold [&_h5]:my-2',
  '[&_h6]:text-heading-sm [&_h6]:font-medium [&_h6]:text-center [&_h6]:my-1',
  '[&_img]:max-h-[225px] [&_img]:rounded-md',
  '[&_video]:rounded-md',
  '[&_li]:marker:text-text',
  '[&_a]:text-text-interactive [&_a]:hover:text-text-interactive-hover [&_a]:hover:no-underline [&_a]:hover:font-bold',
  '[&_p]:my-2',
)

const rehypePlugins = [rehypeRaw]

const MarkdownImage = ({ src, alt }: { src: string; alt: string }) => {
  if (src.endsWith('.mp4')) {
    return (
      <div className='flex justify-center'>
        <Video
          controls
          autoPlay
          hasAudioTrack
          loop
          playsInline
          // playOnHover
          preload='auto'
          src={src}
          className='max-w-full max-h-[225px]'
        />
      </div>
    )
  }
  return (
    <div className='flex justify-center'>
      <Image src={src} alt={alt} className='' />
    </div>
  )
}

const MarkdownImageNative = ({ src, alt }: { src: string; alt: string }) => {
  if (src.endsWith('.mp4')) {
    return (
      <div className='flex justify-center'>
        <video controls autoPlay loop muted playsInline preload='auto' src={src} className='max-w-full max-h-[225px]' />
      </div>
    )
  }
  return (
    <div className='flex justify-center'>
      <img src={src} alt={alt} className='' />
    </div>
  )
}

const MarkdownLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  return (
    <Button variant='link' className='p-0'>
      <a
        href={href}
        target='_blank'
        rel='noreferrer noopener'
        className='text-text-interactive font-bold hover:no-underline'
      >
        {children}
      </a>
    </Button>
  )
}

export default function Markdown({ className, children, native }: MarkdownProps) {
  const components = useMemo(() => {
    return {
      img: native ? MarkdownImageNative : MarkdownImage,
      a: MarkdownLink,
    }
  }, [native])
  return (
    <ReactMarkdown
      className={cls(markdownStyle, className)}
      components={components as any}
      rehypePlugins={rehypePlugins}
    >
      {children}
    </ReactMarkdown>
  )
}
