'use client'

import { cls } from '@/utils'
import Tabs, { TabsProps } from '../tabs'
import IconCupChampionWin from '@haiper/icons-svg/icons/solid/cup-champion-win.svg'
import { FunctionComponent, ReactElement, useMemo, useState } from 'react'
import SubmitEntryEvents from './events'
import SubmitEntrySpotlight from './spotlight'
import SubmitEntryCreationShowcase from './creation-showcase'

export interface SubmitEntryProps {
  className?: string
  title?: string | ReactElement
}

const tabItems: TabsProps['items'] = [
  {
    label: (
      <span className='flex gap-1 items-center'>
        <IconCupChampionWin />
        Events
      </span>
    ),
    value: 'events',
  },
  {
    label: 'Spotlight',
    value: 'spotlight',
  },
  {
    label: 'Creation showcase',
    value: 'creation-showcase',
  },
]

export default function SubmitEntry({ className, title = 'Submit your entry' }: SubmitEntryProps) {
  const [activeTabKey, setActiveTabKey] = useState(tabItems[0].value)

  const Component = useMemo(() => {
    const map: Record<string, FunctionComponent<any>> = {
      events: SubmitEntryEvents,
      spotlight: SubmitEntrySpotlight,
      'creation-showcase': SubmitEntryCreationShowcase,
    }

    return map[activeTabKey] ?? map.events
  }, [activeTabKey])

  return (
    <div className={cls('flex flex-col w-full gap-4', className)}>
      {title ? (
        <div className='font-bold tracking-45 text-2xl text-black dark:text-white' aria-label='title'>
          {title}
        </div>
      ) : null}
      <div className={cls('flex flex-col w-full gap-8')}>
        <div className='' aria-label='tabs'>
          <Tabs
            variant='outline'
            className='bg-transparent'
            listClassName='bg-transparent flex justify-start gap-4'
            itemClassName='px-3 py-1 border border-b-2 border-border rounded-[20px] font-normal'
            activeItemClassName='border-border-active bg-surface'
            items={tabItems}
            value={activeTabKey}
            onChange={setActiveTabKey}
          />
        </div>
        <div className='w-full' aria-label='form'>
          <Component />
        </div>
      </div>
    </div>
  )
}
