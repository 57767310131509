import {
  ApiError,
  Creation,
  CreationOutput,
  // CreationQuery,
  Pagination,
  PaginationQuery,
  Segment,
  SegmentQuery,
} from '@/types'
import { rest } from '@/utils'

export const getCreation = async (id: string): Promise<{ data?: Creation | null; error?: ApiError }> => {
  const res = await rest.get(`/v1/creation/${id}`)
  return res
}

export const getOutput = async (id: string): Promise<{ data?: CreationOutput | null; error?: ApiError }> => {
  const res = await rest.get(`/v2/creation/detail/${id}`)
  return res
}

export const getTopCreations = async (params: PaginationQuery<{}>): Promise<Pagination<CreationOutput>> => {
  const res = await rest.get('/v2/creations/top', { params })
  return res.data
}

export const getTagCreations = async (params: PaginationQuery<{ tag: string }>): Promise<Pagination<Creation>> => {
  const { tag, ...others } = params ?? {}
  const res = await rest.get(`/v1/creations/tag/${tag}`, { params: others })
  return res.data
}

export const postCollectStateToCreationById = async (data: {
  creation_id?: string
  output_id?: string
  is_collect: boolean
}): Promise<void> => {
  await rest.post('/v2/creation/commits/collect', data)
}

export const getUserCreationsSegment = async (
  params: SegmentQuery<{ user_id: string }>,
): Promise<Segment<Creation>> => {
  const { user_id, ...others } = params
  const res = await rest.get(`/v1/user/${user_id}/creations`, {
    params: others,
  })
  return res.data
}

export const postWatermarkFreeUrl = async (creationId: string): Promise<{ url: string; expire_time: string }> => {
  const res = await rest.post(`/v1/creation/${creationId}/watermark-free-url`)
  return res.data
}

export const appealIllegal = async (creationId: string): Promise<any> => {
  const res = await rest.post(`/v2/creation/${creationId}/appeal`)
  return res.data
}
