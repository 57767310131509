import { creationSubmitDialogPayloadAtom } from '@/atoms'
import Dialog from '@/components/dialog'
import { useAtom } from 'jotai'
import { useCallback } from 'react'
import SubmitEntry from '../submit-entry'

export default function CreationSubmitDialog() {
  const [payload, setPayload] = useAtom(creationSubmitDialogPayloadAtom)

  const open = !!payload?.creation

  const handleClose = useCallback(() => {
    setPayload(null)
  }, [setPayload])

  return (
    <Dialog
      open={open}
      title={null}
      titleClassName='mb-3'
      className='gap-0 w-[343px] md:w-[578px] p-4 md:p-6'
      // closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='relative h-max max-h-[90vh] px-4 md:px-14 py-3 md:py-9 w-full md:w-[832px]'>
        <SubmitEntry />
      </div>
    </Dialog>
  )
}
