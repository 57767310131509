import { useAtom } from 'jotai'
import IconCredit from '@/public/assets/diamond.svg'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { cls, formatCurrency, formatMoney } from '@/utils'
import { getCreditDialogOpenAtom } from '@/atoms'
import { useState } from 'react'
import useCredit from '@/hooks/useCredit'
import useAmplitude from '@/hooks/useAmplitude'
import { getCreditCheckoutSession } from '@/service/pay.service'

export default function GetCreditDialog() {
  const [getCreditDialogOpen, setGetCreditDialogOpen] = useAtom(
    getCreditDialogOpenAtom,
  )
  const [currentId, setCurrentId] = useState('')
  const { productsList } = useCredit()
  const [paymentCompleteOpen, setPaymentCompleteOpen] = useState(false)
  const { track } = useAmplitude()

  const confirmRecharge = async () => {
    const currentProduct = productsList.find(
      (item) => item.prices[0].id === currentId,
    )
    if (currentProduct) {
      track('click:credit:getCredits', {
        credits_per_unit: currentProduct.credits_per_unit,
        credit_product_name: currentProduct.product.name,
      })
      const win = window.open('', '_blank')
      try {
        const priceId = currentProduct.prices[0].id
        const successUrl = `${window.location.origin}/payment/success?redirectUrl=${window?.location.pathname || ''}`
        const cancelUrl = `${window.location.origin}/payment/cancel?redirectUrl=${window?.location.pathname || ''}`

        const { url } = await getCreditCheckoutSession({
          price_lookup_key: priceId || '',
          success_url: successUrl,
          quantity: 1,
          cancel_url: cancelUrl,
        })
        win?.location.assign(url)
        win?.focus()
      } catch (error) {
        win?.close()
      }
      setGetCreditDialogOpen(false)
      setPaymentCompleteOpen(true)
    }
  }

  const confirmRefresh = () => {
    window.location.reload()
  }

  const renderCurrentPrice = () => {
    const currentProduct = productsList.find(
      (item) => item.prices[0].id === currentId,
    )
    if (currentProduct) {
      return `Purchase for ${formatCurrency(currentProduct.prices[0].currency || '', '$')}${formatMoney(Number(currentProduct.prices[0].unit_amount) / 100)}`
    }
    return 'Purchase'
  }

  return (
    <>
      <Dialog
        open={getCreditDialogOpen}
        title='Get credits'
        titleClassName='mb-3'
        className='gap-0 w-[343px] md:w-100 p-4 md:p-6'
        footerClassName='mt-[30px]'
        footer={null}
        onOpenChange={(open) => setGetCreditDialogOpen(open)}
      >
        <div className='w-[310px] md:w-[352px] mb-5 text-text-subdued'>
          The credits you purchase now will be added directly to your existing
          credit balance.
        </div>
        <div className='text-text-subdued'>
          VAT charges may apply depending on your country.
        </div>
        <div className='py-4 md:py-6 flex flex-wrap gap-2'>
          {productsList.map((item) => (
            <div
              key={item?.prices[0].id}
              className={cls(
                'w-[151px] md:w-[172px] h-18 md:h-20 text-center py-4 border rounded-md cursor-pointer hover:border-border-hover hover:bg-surface-hover',
                currentId === item?.prices[0].id
                  ? 'border-surface-primary border-2 hover:border-surface-primary'
                  : '',
              )}
              onClick={() => setCurrentId(item?.prices[0].id)}
            >
              <div className='flex justify-center items-center text-body-lg font-medium'>
                <IconCredit className='mr-1' />
                <span>{item?.credits_per_unit}</span>
              </div>
              <div className='text-body-lg font-medium text-text-subdued'>
                {formatCurrency(item?.prices[0].currency ?? '', '$')}{' '}
                {formatMoney(Number(item?.prices[0].unit_amount) / 100)}
              </div>
            </div>
          ))}
        </div>
        <Button
          disabled={currentId === ''}
          variant='primary'
          className='w-full rounded-md'
          data-testid='purchase-button'
          onClick={confirmRecharge}
        >
          {renderCurrentPrice()}
        </Button>
      </Dialog>
      <Dialog
        open={paymentCompleteOpen}
        title='Payment completed?'
        titleClassName='mb-3'
        className='gap-0 w-[343px] md:w-100 p-4 md:p-6'
        footerClassName='mt-[30px]'
        footer={null}
        onOpenChange={(open) => setPaymentCompleteOpen(open)}
      >
        <div className='mb-5 text-text-subdued'>
          If you have completed the payment, please refresh the page to have the
          new balance of credits.
        </div>
        <div className='mb-8 text-text-subdued'>
          If you have an issue of the payment, please contact us via{' '}
          <a className='text-text-interactive' href='mailto:billing@haiper.ai'>
            billing@haiper.ai
          </a>
        </div>
        <Button
          disabled={currentId === ''}
          variant='primary'
          className='w-full mb-2 rounded-md'
          data-testid='refresh-button'
          onClick={confirmRefresh}
        >
          Refresh
        </Button>
      </Dialog>
    </>
  )
}
